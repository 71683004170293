'use client'

import { Stat } from '@ethena/shared-ui/src/components/Stat'
import { StatTooltip } from '@ethena/shared-ui/src/components/StatTooltip'
import { StyledALink } from '@ethena/shared-ui/src/components/StyledALink'
import { DAPP_URL } from '@ethena/shared-ui/src/constants/url'
import { useAvgSusdeYieldTooltipData } from '@ethena/shared-ui/src/hooks/useAvgSusdeYieldTooltipData'
import { useSusdeYieldTooltipData } from '@ethena/shared-ui/src/hooks/useSusdeYieldTooltipData'
import { cn } from '@ethena/shared-ui/utils'
import Link from 'next/link'

import SlantedButtonIcon from '../public/slanted-button.svg'
import { IS_GERMAN } from '@/constants'
import useUserStore from '@/store/user-store'

export const Hero = () => {
  const { hasVisitedHomepage, setHasVisitedHomepage } = useUserStore()
  const { tooltip, yieldStr } = useSusdeYieldTooltipData()
  const { tooltip: avgTooltip, yieldStr: avgYieldStr } = useAvgSusdeYieldTooltipData()

  return (
    <div className="w-fit z-10 my-16 md:mx-10 lg:mx-[100px] self-start animate-in slide-in-from-bottom-8 duration-700 mx-2">
      <div className="flex-1 flex flex-col justify-center gap-5">
        <h1
          aria-label={IS_GERMAN ? 'USDe: ENABLING INTERNET MONEY' : 'ENABLING INTERNET MONEY'}
          className="min-h-[76px] xxs-max:min-h-[113px] sm:min-h-[125px] md:min-h-[144px] text-white text-3xl sm:text-[3rem] md:text-[4rem] leading-tight md:leading-[4.5rem] font-semibold"
        >
          {!hasVisitedHomepage ? (
            <div>
              <span className={cn(IS_GERMAN ? 'typewriter-german-first-line' : 'typewriter-first-line')}></span>
              <br />
              <span className="typewriter-second-line" onAnimationEnd={() => setHasVisitedHomepage(true)}></span>
            </div>
          ) : (
            <div>
              <span>{IS_GERMAN ? 'USDe: ENABLING' : 'ENABLING'}</span>
              <br />
              <span>INTERNET MONEY_</span>
            </div>
          )}
        </h1>
        <h5 className="text-neutrals-400 sm:w-fit text-lg sm:text-xl md:text-2xl font-extralight">
          {`Synthetic Dollar ${!IS_GERMAN ? 'with Internet Native Yield' : ''}`}
        </h5>
        <div className="flex flex-col gap-4 md:gap-5 w-fit flex-wrap">
          {!IS_GERMAN && (
            <Stat
              classes={'stat w-fit border border-neutrals-900 tracking-wide'}
              margin=""
              label="sUSDe APY"
              stat={yieldStr}
              side={{ base: 'right', sm: 'top' }}
              labelWeight="font-bold"
              fontSize="text-lg"
              valueWeight="font-bold"
              tooltipWidth="w-[320px] sm:w-[400px]"
              padding="p-[11px]"
              tooltip={
                <div className="flex flex-col gap-4">
                  <StatTooltip title={`sUSDe APY: ${yieldStr}`} description={tooltip} />
                  <StatTooltip title={`Estimated sUSDe APY: ${avgYieldStr}`} description={avgTooltip} />
                </div>
              }
            />
          )}
        </div>
        {!IS_GERMAN && (
          <Link href={DAPP_URL + '/buy'} className="w-fit -ml-7 -mt-7">
            <SlantedButtonIcon />
          </Link>
        )}
        {IS_GERMAN && (
          <>
            <div>
              <h5 className="w-full sm:w-[400px] mb-1 underline">Imprint</h5>
              <p className="text-xs w-full sm:w-[400px]">
                Ethena GmbH, Kurfürstendamm 15, 10719
                <br />
                Berlin Email: finance@ethenalabs.xyz
                <br />
                Commercial Register: District Court of Charlottenburg HRB 264787 B
                <br />
                Supervisory Authority: Bundesanstalt für Finanzdienstleistungsaufsicht, Marie-Curie-Straße 24-28, 60439
                Frankfurt am Main
                <br />
                Managing Director: Guy Young and Torsten Luettich
                <br />
                Responsible for Content: Torsten Luettich
                <br />
                The European Commission provides a platform for online dispute resolution (OS) at
                http://ec.europa.eu/consumers/odr. We are not obliged nor willing to participate in an online dispute
                resolution procedure.
                <br />
                <br />
              </p>
            </div>
            <div>
              <h5 className="w-full sm:w-[400px] mb-1 underline">Impressum</h5>
              <p className="text-xs w-full sm:w-[400px]">
                Ethena GmbH, Kurfürstendamm 15, 10719 Berlin
                <br />
                Email: finance@ethenalabs.xyz
                <br />
                Handelsregister: Berlin, AG Charlottenburg HRB 264787 B
                <br />
                Regulierunsbehörde: Bundesanstalt für Finanzdienstleistungsaufsicht, Marie-Curie-Straße 24-28, 60439
                Frankfurt am Main
                <br />
                Geschäftsführer: Guy Young and Torsten Luettich
                <br />
                Verantwortlich für journalistisch-redaktionelle Angebote: Torsten Luettich
                <br />
                Die Europäische Kommission stellt unter journalistisch-redaktionelle Angebote: Die Europäische
                Kommission stellt unter http://ec.europa.eu/consumers/odr eine Plattform zur Online-Streitbeilegung (OS)
                bereit. Wir sind nicht verpflichtet und nicht bereit, an einem Verfahren zur Online-Streitbeilegung
                teilzunehmen.
              </p>
            </div>

            <div className="flex flex-wrap gap-4">
              <StyledALink
                url="https://ethena-labs.gitbook.io/ethena-labs/resources/terms-of-service"
                text="Terms of Service"
                classes="text-cblue-500 border-cblue-500 text-xs text-nowrap"
                hideUnderline
              />
              <StyledALink
                text="Terms & Conditions"
                url="https://ethena-labs.gitbook.io/ethena-labs/resources/supplemental-usde-terms-and-conditions#qsh70q"
                classes="text-cblue-500 border-cblue-500 text-xs text-nowrap"
                hideUnderline
              ></StyledALink>
              <StyledALink
                text="Privacy Policy"
                url="https://ethena-labs.gitbook.io/ethena-labs/resources/privacy-policy"
                classes="text-cblue-500 border-cblue-500 text-xs text-nowrap"
                hideUnderline
              ></StyledALink>
            </div>
          </>
        )}
      </div>
    </div>
  )
}
