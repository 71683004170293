'use client'

import { useWebGLEnabled } from '@ethena/shared-ui/src/hooks/useWebGLEnabled'

import Spline from '@splinetool/react-spline'

export function SplineViewer() {
  const isWebGLEnabled = useWebGLEnabled()
  if (!isWebGLEnabled) return null
  return (
    <Spline
      scene="https://prod.spline.design/F1mXrS4WOCFzZcJc/scene.splinecode"
      className="sm:hidden lg:block opacity-25 lg:opacity-100 w-full h-full"
      style={{
        aspectRatio: '5 / 3',
        objectFit: 'contain',
        objectPosition: 'center right',
        pointerEvents: 'auto',
      }}
      onWheel={e => {
        e.stopPropagation()
        window.scrollBy(0, e.deltaY)
      }}
    />
  )
}
