import(/* webpackMode: "eager", webpackExports: ["Hero"] */ "/vercel/path0/landing-page/components/Hero.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["InvestorsBox"] */ "/vercel/path0/landing-page/components/InvestorsBox.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SplineViewer"] */ "/vercel/path0/landing-page/components/SplineViewer.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/landing-page/public/investors/binance-labs.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/landing-page/public/investors/bybit.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/landing-page/public/investors/deribit.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/landing-page/public/investors/dragonfly.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/landing-page/public/investors/fidelity.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/landing-page/public/investors/franklintempleton.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/landing-page/public/investors/okx.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/landing-page/public/investors/wintermute.svg");
